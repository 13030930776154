var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-box-two"},[(_vm.isLoading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"image","height":"70"}})],1):_c('div',{class:['overflow-hidden bg-white', { 'rounded border': !_vm.noBorder }]},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"minw-0 flex-shrink-0",attrs:{"cols":null}},[_c('div',{staticClass:"px-3 d-flex flex-column py-1"},[_c('div',{staticClass:"order-2 fs-14 lh-1 fc-vi"},[(
                _vm.productDetails.base_price >
                  _vm.productDetails.base_discounted_price
              )?[_c('del',{staticClass:"opacity-40"},[_vm._v(_vm._s(_vm.format_price(_vm.productDetails.base_price)))]),_c('span',{staticClass:"fw-700"},[_vm._v(_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price)))])]:[_c('span',{staticClass:"fw-700"},[_vm._v(_vm._s(_vm.format_price(_vm.productDetails.base_discounted_price)))])]],2),_c('h5',{staticClass:"opacity-60 fw-400 mb-2 lh-1-6 text-truncate fs-12"},[_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                name: 'ProductDetails',
                params: { slug: _vm.productDetails.slug },
              }}},[_vm._v(_vm._s(_vm.productDetails.name))])],1)])]),_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":"auto"}},[_c('div',{staticClass:"position-relative"},[_c('router-link',{staticClass:"text-reset d-block lh-0 text-center",attrs:{"to":{
              name: 'ProductDetails',
              params: { slug: _vm.productDetails.slug },
            }}},[_c('img',{staticClass:"img-fit size-70px",attrs:{"src":_vm.addResourceImage(_vm.productDetails.thumbnail_image),"alt":_vm.productDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }