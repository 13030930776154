<template>
  <div class="product-box-two">
    <div v-if="isLoading">
      <v-skeleton-loader
        type="image"
        height="70"
      />
    </div>
    <div :class="['overflow-hidden bg-white', { 'rounded border': !noBorder }]" v-else>
      <v-row
        align="center"
        no-gutters
        class="flex-nowrap"
      >
        
        <v-col
          :cols="null"
          class="minw-0 flex-shrink-0"
        >
          <div
            class="px-3 d-flex flex-column py-1"
          >
            <div
              class="order-2 fs-14 lh-1 fc-vi"
            >
              <template
                v-if="
                  productDetails.base_price >
                    productDetails.base_discounted_price
                "
              >
                <del class="opacity-40">{{
                  format_price(productDetails.base_price)
                }}</del>
                <span class="fw-700">{{
                  format_price(productDetails.base_discounted_price)
                }}</span>
              </template>
              <template v-else>
                <span class="fw-700">{{
                  format_price(productDetails.base_discounted_price)
                }}</span>
              </template>
            </div>
            <h5
              class="opacity-60 fw-400 mb-2 lh-1-6 text-truncate fs-12"
            >
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { slug: productDetails.slug },
                }"
                class="text-reset"
              >{{ productDetails.name }}</router-link
              >
            </h5>
          
          </div>
        </v-col>
        <v-col cols="auto" class="flex-shrink-0">
          <div class="position-relative">
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { slug: productDetails.slug },
              }"
              class="text-reset d-block lh-0 text-center"
            >
              <img
                :src="addResourceImage(productDetails.thumbnail_image)"
                :alt="productDetails.name"
                @error="imageFallback($event)"
                class="img-fit size-70px"
              />
            </router-link>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    isLoading: { type: Boolean, required: true, default: true },
    noBorder: { type: Boolean, default: false },
    productDetails: { type: Object, required: true, default: () => ({}) },
  },
  data: () => ({}),
  computed: {
    ...mapGetters('wishlist', ['isThisWishlisted']),
    ...mapGetters('cart', ['isThisInCart', 'findCartItemByVariationId']),
    discount() {
      return this.discount_percent(
        this.$props.productDetails.base_price,
        this.$props.productDetails.base_discounted_price
      )
    },
  },
  methods: {
    ...mapActions('wishlist', ['addNewWishlist', 'removeFromWishlist']),
    ...mapActions('cart', ['addToCart', 'updateQuantity']),
    ...mapMutations('auth', ['showAddToCartDialog']),
    addCart() {
      if (!this.$props.productDetails.is_variant) {
        this.addToCart({
          variation_id: this.$props.productDetails.variations[0].id,
          qty: this.$props.productDetails.min_qty,
        })
      }
    },
    updateCart(type, cart_id) {
      this.updateQuantity({
        type: type,
        cart_id: cart_id,
      })
    },
  },
}
</script>

<style scoped>
.fc-vi {
  color: #AF74FF;;
}
.bg-white {
  background: #fff;
}
</style>
